<template>
  <div id="app">
    <!-- 组件是一个 functional 组件，渲染路径匹配到的视图组件。-->
      <router-view></router-view>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* 在 main.css 文件中 */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
