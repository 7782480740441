import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/airclear',
    name: 'airclear',
    component: () => import('../views/AirClearView.vue')
  },
  {
    path: '/dehumidifier',
    name: 'dehumidifier',
    component: () => import('../views/DehumidifierView.vue'),
  },
  {
    path: '/humidify',
    name: 'humidify',
    component: () => import('../views/HumidifyView.vue'),
  },
  {
    path: '/fan',
    name: 'fan',
    component: () => import('../views/FanView.vue')
  },
  {
    path: '/heater',
    name: 'heater',
    component: () => import('../views/HeaterView.vue')
  },
  {
    path: '/dustmite',
    name: 'dustmite',
    component: () => import('../views/DustmiteView.vue')
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('../views/AppDownloadView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/phoneus',
    name: 'phoneus',
    component: () => import('../views/PhoneUsView.vue')
  },
  {
    path: '/after',
    name: 'after',
    component: () => import('../views/AfterView.vue')
  },
  // 商品详情页
  {
    path: '/A9H',
    name: 'A9H',
    component: () => import('../views/product/A9H.vue')
  },
  {
    path: '/R4W',
    name: 'R4W',
    component: () => import('../views/product/R4W.vue')
  },
  {
    path: '/A10',
    name: 'A10',
    component: () => import('../views/product/A10.vue')
  },
  {
    path: '/B6',
    name: 'B6',
    component: () => import('../views/product/B6.vue')
  },
  {
    path: '/D1',
    name: 'D1',
    component: () => import('../views/product/D1.vue')
  },
  {
    path: '/D5',
    name: 'D5',
    component: () => import('../views/product/D5.vue')
  },
  {
    path: '/D6',
    name: 'D6',
    component: () => import('../views/product/D6.vue')
  },
  {
    path: '/F3A',
    name: 'F3A',
    component: () => import('../views/product/F3A.vue')
  },
  {
    path: '/F4',
    name: 'F4',
    component: () => import('../views/product/F4.vue')
  },
  {
    path: '/H8',
    name: 'H8',
    component: () => import('../views/product/H8.vue')
  },
  {
    path: '/H8Pro',
    name: 'H8Pro',
    component: () => import('../views/product/H8Pro.vue')
  },
  {
    path: '/H16',
    name: 'H16',
    component: () => import('../views/product/H16.vue')
  },
  {
    path: '/W30',
    name: 'W30',
    component: () => import('../views/product/W30.vue')
  }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 定位页面头部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})


// const router = createRouter({
//   // 没有后端的情况下才可以使用，有后端的话后端无法生效
//   // history: createWebHistory(),
//   // history: createWebHashHistory(),
//   base: process.env.BASE_URL,
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     console.log('scrollBehavior called');
//     return { x: 0, y: 0 };
//   }
// })


export default router
